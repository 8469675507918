
/* =================== SIDE =================== */
jQuery(document).ready(function($){
    function toggleSide() {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > 100) {
            $('.side-contatos').removeClass('oculto');
        } else {
            $('.side-contatos').addClass('oculto');
        }
    }
    toggleSide();
    $(window).on('scroll', debounce(function(){
        toggleSide();        
    }, 100));
});
